import { navigate } from 'gatsby'
import React from 'react'
import { compose } from 'recompose'
import { AuthUserContext, withAuthorization } from '../../components/Session'

function index() {
    return (
        <AuthUserContext.Consumer>
            {authUser => (
                navigate("galleries")
            )}
        </AuthUserContext.Consumer>
    )
}
const condition = authUser => !!authUser

export default  compose(
    withAuthorization(condition),
)(index)
